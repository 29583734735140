<style lang="scss" src="./index.scss"></style>
<template>
  <div :class="{
    'wgt-oldEvaluate': true,
    'padded': value.padded
  }">
    <div class="wgt-bd">
      <div v-for="(item, index) in value.data" :key="index" v-if="(value.radioOldType || 'on') == item.old_type">
        <div class="slider-item-old_type" v-if="item.old_type == 'on'">
          <SpImage :src="item.data.imgUrl" fit="contain" />
          <div v-if="item.price_show_type != 0" class="old_type-on" id="filler" :style=" `color:${item.fontColor};font-size:${item.fontSize}px;top:${item.textTopPer * 100}%;left:${item.textLeftPer * 100}%;line-height:${item.fontSize}px;letter-spacing: 0px;`">
            {{item.price_show_type == 1 ? '预估本机最高可抵' : '在线折抵最高立减'}}0元
          </div>
          <div v-if="item.price_show_type != 0 && item.isShowSubsidyNoPrice" class="old_type-on" id="filler" :style=" `font-size:${item.subsidyFontSize}px;top:${item.subsidyTextTopPer * 100}%;left:${item.subsidyTextLeftPer * 100}%;line-height:${item.subsidyFontSize}px;letter-spacing: 0px;`">
            含换新补贴最高<span :style="`color:${item.subsidyFontColor};`">0</span>元
          </div>
        </div>
        <div v-if="item.old_type == 'yes'">
          <SpImage v-for="(i,ix) in item.data" :src="i.imgUrl" :key="'old_type'+ix" fit="contain" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from './config'
export default {
  name: 'oldEvaluate',
  wgtName: '旧机评估',
  wgtDesc: '',
  wgtIcon: 'wgt-old-evaluate',
  wgtType: 1,
  wgtTem:1,//0代表微信和抖音小程序都可以配置,1代表只能配置微信
  config: config,
  props: {
    value: [Object, Array]
  },
  data() {
    return {
      currentIndex: 0
    }
  },
  computed: {
  },
  created() { },
  methods: {}
}
</script>
