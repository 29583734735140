// 微信小程序自定义页装修
import {
  WgtSlider,
  WgtNewSlider,
  WgtHotZone,
  WgtHoriZontal,
  WgtblokScroll,
  WgtFilm,
  WgtAbtest,
  WgtAroundService,
  WgtStickyLift,
  WgtStoreRuleList,
  WgtTabsCut,
  WgtGoodsLift,
  WgtOldGoods,
  WgtOldEvaluate,
  WgtWallPaper,
  WgtAroundStore,
  WgtAroundStoreOne,
  WgtFooterNav,
  WgtProductShelves,
  WgtCustomWgt,
  WgtContentPart,
  WgtImageText, 
  WgtClassify
} from '../wgts/index'

export const initiWidgets = []

export const widgets = {
  WgtSlider,
  WgtNewSlider,
  WgtHotZone,
  WgtHoriZontal,
  WgtblokScroll,
  WgtFilm,
  WgtAbtest,
  WgtAroundService,
  WgtStickyLift,
  WgtStoreRuleList,
  WgtTabsCut,
  WgtGoodsLift,
  WgtOldGoods,
  WgtOldEvaluate,
  WgtWallPaper,
  WgtAroundStore,
  WgtAroundStoreOne,
  WgtFooterNav,
  WgtProductShelves,
  WgtCustomWgt,
  WgtContentPart,
  WgtImageText, 
  WgtClassify
}


export default {
  widgets,
  initiWidgets
}
