// 商城首页装修
import {
  WgtPage,
  WgtNewSlider,
  WgtSlider,
  WgtHotZone,
  WgtHoriZontal,
  WgtblokScroll,
  WgtFilm,
  WgtAroundService,
  WgtStickyLift,
  WgtTabsCut,
  WgtOldGoods,
  WgtOldEvaluate,
  WgtWallPaper,
  WgtAroundStore,
  WgtAroundStoreOne,
  WgtAbtest,
  WgtProductShelves,
  WgtContentPart,
  WgtCustomWgt,
  WgtImageText, 
  WgtClassify
} from '../wgts'

export const initiWidgets = []

export const widgets = {
  WgtPage,
  WgtSlider,
  WgtNewSlider,
  WgtHotZone,
  WgtHoriZontal,
  WgtblokScroll,
  WgtFilm,
  WgtAroundService,
  WgtStickyLift,
  WgtTabsCut,
  WgtOldGoods,
  WgtOldEvaluate,
  WgtWallPaper,
  WgtAroundStore,
  WgtAroundStoreOne,
  WgtAbtest,
  WgtCustomWgt,
  WgtProductShelves,
  WgtContentPart,
  WgtImageText, 
  WgtClassify
}



export default {
  widgets,
  initiWidgets
}
