<style lang="scss" scoped>
.data-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;

  &__label {
    margin-right: 20px;
  }
}
</style>
<style lang="scss">
.attr_slider {
  .todo-list {
    align-items: flex-start;
  }

  &_item {
    display: flex;

    .image-item {
      height: 71px !important;
      box-sizing: border-box;

      .el-image {
        height: 69px !important;
      }
    }
  }
}

.attr_slider-picker-link {
  margin-left: 15px;

  .h5-link {
    margin-top: 5px !important;
  }
}

.oldPrice-control {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
}

.oldPrice-set {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  .set-item {
    display: flex;
    align-items: center;

    .text {
      margin-right: 10px;
    }
  }

  .el-input {
    width: 100px;
  }
}

.old_card {
  min-width: 80%;
}

.dialog-coneten {
  width: 375px;
  position: relative;
}

.drag_resize {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 2px #aaa;

  .resizable {
    border: 1px dotted #bbb;
    position: absolute;
    left: 0;
    top: 0
  }

  .handle {
    box-sizing: border-box;
    position: absolute;
    width: 8px;
    height: 8px;
    background: #ffffff;
    border: 1px solid #333;
    box-shadow: 0 0 2px #bbb;
  }
}

.back-img {
  // position: absolute;
  width: 375px;
  height: auto;
  display: block;
}

.filler {
  width: 100%;
  height: 100%;
  display: inline-block;
  position: absolute;
  letter-spacing: 0px;

  &:hover {
    cursor: move;
  }
}

.set-backdrop{
  display: flex;
  margin-bottom: 20px;

  &_select{
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .back_img{
    width: 100%;
    height: auto;
  }
}
</style>
<template>
  <div class="attr_slider">
    <el-form label-width="100px" label-position="left">
      <!-- 未评估 -->
      <el-form-item label="" label-width="0px" v-if="radioOldType == 'on'">
        <AttrHotSetting v-model='localValue[0].data' :uuid='uuid' />
        <!-- <div class="oldPrice-control" v-if="localValue[0].data.imgUrl">
          <span class="text">展示同城帮最高折抵金额</span>
          <el-switch v-model="localValue[0].oldPrice"></el-switch>
        </div> -->
        <div v-if="localValue[0].data.imgUrl" style="display:flex;align-items:center;">
          <span class="text" style="margin-right: 10px;">折抵金额:</span>
          <el-radio-group v-model="localValue[0].price_show_type">
            <el-radio :label="0">不显示</el-radio>
            <el-radio :label="1">机型折抵</el-radio>
            <el-radio :label="2">最高折抵</el-radio>
          </el-radio-group>
        </div>
        <div class="oldPrice-set" v-if="localValue[0].price_show_type != 0 && localValue[0].data.imgUrl">
          <div class="set-item">
            <span class="text">字体大小</span>
            <el-input type="number" v-model="localValue[0].fontSize" placeholder=""></el-input>
          </div>
          <div class="set-item">
            <span class="text">字体颜色</span>
            <el-color-picker v-model="localValue[0].fontColor"></el-color-picker>
          </div>
        </div>
        <el-button v-if="localValue[0].price_show_type != 0 && localValue[0].data.imgUrl" type="primary"
          @click="onDialogVisible('')">自定义位置</el-button>

        <div v-if="localValue[0].price_show_type != 0 && localValue[0].data.imgUrl">
          <div class="oldPrice-control">
            <span class="text">展示同城帮补贴金额</span>
            <el-switch v-model="localValue[0].isShowSubsidyNoPrice"></el-switch>
          </div>
          <div class="oldPrice-set" v-if='localValue[0].isShowSubsidyNoPrice'>
            <div class="set-item">
              <span class="text">字体大小</span>
              <el-input type="number" v-model="localValue[0].subsidyFontSize" placeholder=""></el-input>
            </div>
            <div class="set-item">
              <span class="text">字体颜色</span>
              <el-color-picker v-model="localValue[0].subsidyFontColor"></el-color-picker>
            </div>
          </div>
          <el-button v-if='localValue[0].isShowSubsidyNoPrice' type="primary"
            @click="onDialogVisible('subsidy')">自定义位置</el-button>
        </div>
      </el-form-item>

      <!-- 已评估 -->
      <el-form-item label="" label-width="0px" v-if="radioOldType == 'yes'">
        <div class="set-backdrop">
          <span class="text">设置背景图</span>
          <div class="set-backdrop_select">
            <img v-if="localValue[1].backdrop_img" :src="localValue[1].backdrop_img" class="back_img" alt="">
            <div>
              <el-button type="primary" size="small" plain @click="onSelectImage"> 选择图片 </el-button>
              <el-button v-if="localValue[1].backdrop_img" type="primary" size="small" plain @click="onDeleteBackdrop"> 删除 </el-button>
            </div>
          </div>
        </div>

        <div class="set-backdrop">
          <span class="text">设置背景色</span>
          <div class="set-backdrop_select">
            <el-color-picker v-model="localValue[1].backdrop_color"></el-color-picker>
          </div>
        </div>

        <el-card class="old_card" v-for="(item, index) in localValue[1].data" :key="index + 'item'">
          <div slot="header" class="clearfix">
            <span>{{ item.type == 'top' ? '评估结果上方' : '评估结果下方' }}</span>
          </div>
          <AttrHotSetting v-model='localValue[1].data[index]' :uuid='uuid' />
        </el-card>
      </el-form-item>
    </el-form>
 以旧换新 放置区域
    <el-dialog title="自定义位置" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <div class="dialog-coneten" id="dialog_drag_resize">
        <img :src="localValue[0].data.imgUrl" class="back-img" alt="">
        <div class="drag_resize" v-if="dragResize">
          <VueDraggableResizable axis="both" :w="resizableW" :h="localValue[0].fontSize" :x="position.left"
            :y="position.top" :parent="true" :debug="false" :isConflictCheck="false" :snap="true" :snapTolerance="1"
            @dragging="(x, y) => onDragging(x, y, '')">
            <div class="filler"
              :style="`color:${localValue[0].fontColor};font-size:${localValue[0].fontSize}px;line-height:${localValue[0].fontSize}px`">
              {{ localValue[0].price_show_type == 1 ? '预估本机最高可抵' : '在线折抵最高立减' }}0元
            </div>
          </VueDraggableResizable>
          <VueDraggableResizable axis="both" :w="resizableW" :h="localValue[0].subsidyFontSize" :x="position.subLeft"
            :y="position.subTop" :parent="true" :debug="false" :isConflictCheck="false" :snap="true" :snapTolerance="1"
            @dragging="(x, y) => onDragging(x, y, 'subsidy')">
            <div class="filler">
              <span>含换新补贴最高</span>
              <span
                :style="`color:${localValue[0].subsidyFontColor};font-size:${localValue[0].subsidyFontSize}px;line-height:${localValue[0].subsidyFontSize}px`">0</span>
              <span>元</span>
            </div>
          </VueDraggableResizable>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import CompHotPicker from '../../comps/comp-hotpicker.vue'
import AttrHotSetting from '../wgt_hotzone/attr-hotsetting.vue'
import VueDraggableResizable from 'vue-draggable-resizable-gorkys'


export default {
  name: 'AttrItem',
  components: {
    CompHotPicker,
    AttrHotSetting,
    VueDraggableResizable
  },
  props: {
    value: {
      type: Array
    },
    uuid: {
      type: String
    },
    radioOldType: {
      type: String
    }
  },
  data() {
    return {
      localValue: [],
      dialogVisible: false,
      dialogType: '',
      position: {
        width: 0,
        height: 0,
        top: 0,
        left: 0,
        subTop: 0,
        subLeft: 0
      },
      listWidth: 0,
      listHeight: 0,
      resizableW: 170,
      dragResize: false
    }
  },
  methods: {
    handleClose() {
      this.dialogVisible = false
      this.dialogType = ''
      this.dragResize = false
    },
    onDialogVisible(type) {
      this.dialogVisible = !this.dialogVisible
      this.dialogType = type
      // 初始化
      setTimeout(() => {
        // 获取设置可拖拽移动范围
        let listEl = document.getElementById('dialog_drag_resize');
        let filler = document.getElementById('filler'); //获取悬浮文字节点宽度
        this.resizableW = filler.clientWidth + 5;
        this.listWidth = listEl.clientWidth;
        this.listHeight = listEl.clientHeight;

        // 初始化定位位置
        let top = 0
        let left = 0
        let subTop = 0
        let subLeft = 0
        top = this.localValue[0].textTopPer * listEl.clientHeight
        left = this.localValue[0].textLeftPer * listEl.clientWidth
        subTop = this.localValue[0].subsidyTextTopPer * listEl.clientHeight
        subLeft = this.localValue[0].subsidyTextLeftPer * listEl.clientWidth
        this.position.top = Number(top)
        this.position.left = Number(left)
        this.position.subTop = Number(subTop)
        this.position.subLeft = Number(subLeft)
        this.dragResize = true
      }, 1000)
    },

    onDragging(x, y, type) {
      let top = y / this.listHeight
      let left = x / this.listWidth
      let subTop = y / this.listHeight
      let subLeft = x / this.listWidth
      if (type === 'subsidy') {
        this.localValue[0].subsidyTextTopPer = subTop // 0.18兼容换新补贴文案 位置错位情况
        this.localValue[0].subsidyTextLeftPer = subLeft
      } else {
        this.localValue[0].textTopPer = top
        this.localValue[0].textLeftPer = left
      }
    },
    async onSelectImage() {
      const {
        data: { url }
      } = await this.$picker.image({
        data: { url: this.localValue[1].backdrop_img }
      })
      this.localValue[1].backdrop_img = url
    },
    onDeleteBackdrop() {
      this.localValue[1].backdrop_img = ''
    }
  },
  watch: {
    uuid: {
      handler() {
        this.localValue = cloneDeep(this.value)
      }
    },
    localValue: {
      handler(nVal) {
        this.$emit('input', nVal)
      },
      immediate: true,
      deep: true,
    }
  },
  created() {
    this.localValue = cloneDeep(this.value)
  }
}
</script>
