import { pickBy } from "@/utils";
import AttrItem from "./attr-item.vue";

export default {
  name: "tabsCut",
  setting: [
    { label: "标题", key: "title", component: "input", value: "Tabs切换" },
    {
      label: "副标题",
      key: "subtitle",
      component: "input",
      value: "可设置切换不同的展示内容",
    },
    { label: "楼层", key: "floor_var", component: "input" },
    { label: "组件间距", key: "padded", component: "switch", value: true },
    {
      label: '切换项',
      key: 'data',
      component: function (h, { key }) {
        return <AttrItem v-model={this.value[key]} uuid={this.value.uuid} />;
      },
      value: []
    }
  ],
  transformIn: (v) => {
    const { name, base, config, data } = v;
    return {
      name,
      ...base,
      ...config,
      data: data.map((item) => {
        if (item?.isShowNearStore) {
          return item
        } else {
          return {
            ...item,
            isShowNearStore: 'hide',
            nearStroeTop: 70,
            nearStoreWidth: 339,
          }
        }
      }),
    };
  },
  transformOut: (v) => {
    return pickBy(v, {
      name: "name",
      base: (v) => {
        return pickBy(v, {
          title: "title",
          subtitle: "subtitle",
          floor_var: "floor_var",
          padded: "padded",
        });
      },
      config: () => {
        return { ruleImgUrl: '' }
      },
      data: ({ data }) => {
        return data.map(item => {
          const { fixedBtnImg, goodsType, goodsSort, anchorType, hotzoneList, fixedBtnData, isShowNearStore, nearStroeTop, nearStoreWidth, } = item
          return {
            fixedBtnImg,
            fixedBtnData,
            goodsType,
            goodsSort,
            anchorType,
            hotzoneList,
            isShowNearStore,
            nearStroeTop,
            nearStoreWidth,
          }
        })
      },
    });
  },
};
