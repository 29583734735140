<style lang="scss">

.hidden-search{
  .wgt-page-content {
    padding-right: 0px!important;
  }
}
.wgt-page {
  position: sticky;
  left: 0px;
  top: 0px;
  z-index: 999;

  &-content {
    // background-image: url('../../../../assets/imgs/weapp-header.png');
    height: 64px;
    width: 375px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    overflow: hidden;
  }

  .wgt-page-content {
    display: flex;
    box-sizing: border-box;

    .search-icon {
      width: 32px;
      height: 32px;
      background-color: rgba($color: #fff, $alpha: 0.6);
      border-radius: 100%;
      margin-top: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid rgba(153, 153, 153, 0.2);
      position: absolute;
      right: 100px;

      i {
        color: #000000;
      }
    }
  }
  .header-container {
    height: 32px;
    margin-top: 26px;
    flex: 1;
    line-height: 32px;

    // align-items: center;
    .title-image {
      display: inline-block;
      margin-top: 6px;
    }
  }

  &.immersive {
    position: absolute;
    z-index: 999;
    background-color: transparent !important;

    .title-image {
      visibility: hidden;
    }
  }
}
</style>
<template>
  <div v-if="value" :class="['wgt-page', { 'immersive': value.style == 'immersive','hidden-search':value.showsearch!=1 }]" :style="headerStyle"
    @click="handleClickHeader">
    <div class="wgt-page-content" :style="contentStyle">
      <div class="header-container" :style="containerStyle">
        <span v-if="value.titleStyle == '1'">{{ value.titleText }}</span>
        <sp-image v-else fit="contain" :height="22" class="title-image" :src="value.titleBackgroundImage" />
      </div>
      <p class="search-icon" v-if="value.showsearch==1">
        <i class="el-icon-search" :style="`color:${value.titleColor == 'black' ? '#000000' : '#ffffff'}`" />
      </p>
    </div>
  </div>
</template>

<script>
const weappHeaderDark = require('@/assets/imgs/weapp-header-dark.png')
const weappHeaderLight = require('@/assets/imgs/weapp-header-light.png')
import config from './config'
export default {
  name: 'Page',
  wgtName: '页面设置',
  wgtDesc: '',
  wgtType: 0,
  wgtTem: 1,//0代表微信和抖音小程序都可以配置,1代表只能配置微信
  config: config,
  props: {
    click: Function,
    value: [Object, Array]
  },
  data() {
    return {}
  },
  computed: {
    headerStyle() {
      const { navigateStyle, navigateBackgroundColor, style, navigateBackgroundImage, immersive } =
        this.value || {}
      const styleValue = {
        // 'display': immersive ? 'none' : 'block'
      }
      if (navigateStyle == '1') {
        Object.assign(styleValue, {
          'background-color': navigateBackgroundColor
        })
      } else {
        Object.assign(styleValue, {
          'background-size': 'cover',
          'background-position': 'center'
        })
      }
      if (style == 'standard') {
        Object.assign(styleValue, {
          'background-image': `url(${navigateBackgroundImage})`,
        })
      }
      return styleValue
    },
    contentStyle() {
      const { titleColor,immersiveColor,style } = this.value || {}
      return {
        'background-image': `url(${titleColor == 'black' ? weappHeaderDark : weappHeaderLight})`,
        backgroundColor:style == 'immersive'?immersiveColor:'transparent'
      }
    },
    containerStyle() {
      const { titlePosition, titleColor } = this.value || {}
      return {
        'text-align': titlePosition,
        'color': titleColor,
        'margin-left': titlePosition == 'left' ? '15px' : '0px'
      }
    }
  },
  created() { },
  methods: {
    handleClickHeader() {
      this.$emit('change')
    }
  }
}
</script>
