import { pickBy } from '@/utils'
import { proportions } from '@/consts'
import CompsStyle from "../../comps/comps-style.vue";

export default {
  name: 'film',
  setting: [
    { label: '标题', key: 'title', component: 'input', value: '标题' },
    { label: '副标题', key: 'subtitle', component: 'input', value: '副标题' },
    { label: '资源位名称', key: 'flowName_var', component: 'input' },
    { label: '楼层', key: 'floor_var', component: 'input' },
    {
      label: "圆角",
      key: "radius",
      component: "number",
      position: "right",
      size: "mini",
      value: 0,
      append: "px",
      min: 0,
    },
    {
      label: '组件间距', key: 'padd', component: function (h, { key }) {
        return <CompsStyle v-model={this.value[key]} size="small" showLabel={false} showBgsetting={false} />;
      },
      value: {
        paddedt: 10,
        paddedb: 10,
        paddedl: 10,
        paddedr: 10,
      },
    },
    {
      label: "视频封面",
      key: "video_cover",
      component: function (h, { key }) {
        return <SpImagePicker v-model={this.value[key]} size="small" />;
      },
      value: "",
    },
    { label: "自动播放", key: "autoplay", component: "switch", value: false },
    {
      label: '选择视频',
      key: 'data',
      component: function (h, { key }) {
        return <SpVideoPicker v-model={this.value[key]} size='small' />
      },
      value: {}
    },
    {
      label: '视频比例',
      key: 'proportion',
      component: 'radio',
      options: proportions,
      value: 0
    }
  ],
  transformIn: (v) => {
    const {
      name,
      base:{padd,...otherBase},
      config,
      data: [_data]
    } = v
    return {
      name,
      padd:{
        paddedt: padd?.paddedt || 0,
        paddedb: padd?.paddedb || 0,
        paddedl: padd?.paddedl || 0,
        paddedr: padd?.paddedr || 0,
      },
      ...otherBase,
      ...config,
      data: Object.keys(_data).length > 0 ? _data : {}
    }
  },
  transformOut: (v) => {
    return pickBy(v, {
      name: 'name',
      base: (v) => {
        return pickBy(v, {
          title: 'title',
          subtitle: 'subtitle',
          padd: 'padd',
          flowName_var: 'flowName_var',
          floor_var: 'floor_var',
          video_cover: 'video_cover',
          proportion: 'proportion',
          radius:'radius'
        })
      },
      config: (v) => {
        return pickBy(v, {
          autoplay: "autoplay",
        });
      },
      data: ({ data }) => {
        return [data];
      }
    })
  }
}
