<style lang="scss" scoped>
.tabs-attr-item {
  width: 100%;
  height: auto;

  .el-form-item {
    margin-bottom: 0px;
  }
  .comp-picker-link{
     margin-top: 10px;
     margin-bottom: 10px;
  }
}

.tabs-items {
  display: flex;
  border-bottom: 3px dashed #ebeef5;
}

.icon-trash-alt1 {
  color: #888;
  font-size: 13px;
}
</style>
<style lang="scss">
.tabs-attr-item {
  .el-radio-group {
    margin-top: 12px !important;
  }
}
</style>
<style lang="scss">
.tabs-attr-item{
    .input-m {
      width: 98px;
  }
}</style>
<template>
  <div class="tabs-attr-item">
    <CompTodoList v-model="localValue" @onAddItem="handleAddTabs" btnText="添加切换项" :isMove='false'>
      <template slot="body" slot-scope="scope">
        <div class="tabs-items">
          <el-form label-width="100px" label-position="left">
            <el-form-item label="热区" label-width="40px">
              <AttrSetting :value='localValue[scope.index].hotzoneList' :datas="localValue"
                @change="(val) => inputChange(val, scope.index)" :uuid='value.uuid' />
            </el-form-item>
            <el-form-item label="身边门店">
              <el-radio-group v-model="localValue[scope.index].isShowNearStore">
                <el-radio label="show">展示</el-radio>
                <el-radio label="hide">隐藏</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="身边门店设置" v-if="localValue[scope.index].isShowNearStore=='show'">
              <el-input-number placeholder='顶部距离' v-model='localValue[scope.index].nearStroeTop' size='mini' :min='0' :step='1' controls-position='right' style="margin-bottom: 5px;"/>
              <el-input-number placeholder='宽度' v-model='localValue[scope.index].nearStoreWidth' size='mini' :min='0' :step='1' controls-position='right' />
            </el-form-item>
            <el-form-item label="关联商品">
              <el-radio-group v-model="localValue[scope.index].goodsType">
                <el-radio label="show">展示</el-radio>
                <el-radio label="hide">隐藏</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="localValue[scope.index].goodsType == 'show'" label="商品排序位置">
              <el-radio-group v-model="localValue[scope.index].goodsSort">
                <el-radio v-for="(a, s) in localValue[scope.index].hotzoneList" :label="s" :key="s">热区{{ s + 1
                }}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="锚点位置">
              <el-radio-group v-model="localValue[scope.index].anchorType">
                <el-radio label="tabs-goods">关联商品</el-radio>
                <el-radio v-for="(q, w) in localValue[scope.index].hotzoneList" :label="'anchor' + w" :key="w">热区图{{ w + 1
                }}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="底部悬浮按钮">
              <SpImagePicker v-model="localValue[scope.index].fixedBtnImg" size="small" />
              <CompHotPicker v-model='localValue[scope.index].fixedBtnData' :isShowRadio='false'
                @change='(e) => onChangeLink(e, scope.index)' :isShowLinkprogram="true" :isShowVideo="false"/>
            </el-form-item>
          </el-form>
        </div>
      </template>
    </CompTodoList>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import CompHotPicker from '../../comps/comp-hotpicker'
import CompTodoList from '../../comps/comp-todoList'
import AttrSetting from './attr-setting'
export default {
  name: 'attrItem',
  components: { CompTodoList, CompHotPicker, AttrSetting },
  props: {
    value: {
      type: Array
    },
    uuid: {
      type: String
    }
  },
  data() {
    return {
      localValue: []
    }
  },
  computed: {
  },
  watch: {
    localValue: function (nVal, oVal) {
      this.$emit('input', nVal)
    },
    uuid: {
      handler(val) {
        this.localValue = cloneDeep(this.value)
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    inputChange(nval, pindex) {
      this.localValue[pindex].hotzoneList = nval
    },
    handleAddTabs() {
      const item = {
        hotzoneList: [],
        goodsType: 'show',
        isShowNearStore:'hide',
        nearStroeTop: 70,
        nearStoreWidth:339,
        anchorType: 'tabs-goods',
        goodsSort: '',
        fixedBtnImg: '',
        fixedBtnData: {
          linkPage: '',
          title: '',
          id: '',
          card_type: '',
          linkTitle: '',
          linkProgram: '1',
          linkAppid: '',
          CopyUrl: '',
          // CopyTitle: '',
          goods_id: '',
          rule_id: '0',
          // hotzoneType: 'link',
          // hotzoneTypeIndex: 0,
        }
      }
      this.localValue.push(item)
    },
    onChangeLink(e, index) {
      const oldVal = this.localValue[index]
      this.$set(this.localValue, index, Object.assign(oldVal, e))
    },
  }
}
</script>
