<style lang="scss">
.wgt-hotzone {
  background-repeat: no-repeat;
  background-size: cover;

  &.padded {
    padding: 10px 0;
  }

  .wgt-hd {
    padding: 10px;

    .title {
      font-size: 18px;
      font-weight: 600;
      color: #333;
    }

    .sub-title {
      color: #666;
      margin-left: 4px;
    }
  }

  .wgt-bd {
    position: relative;

    &.spaced {
      padding: 0 10px;

      .slider-container {
        right: 10px;
        left: 10px;
      }

      .slider-pagination {
        right: 10px;
        left: 10px;
      }
    }
  }
}
</style>
<template>
  <div
  :class="{
    'wgt-hotzone': true,
    'padded': value.padded
  }" >
    <div v-if="value.title || value.subtitle" class="wgt-hd">
      <span class="title">{{ value.title }}</span>
      <span class="sub-title">{{ value.subtitle }}</span>
    </div>
    <div class="wgt-bd"  v-if="value.data.imgUrl"
      :class="{
        'spaced': value.spaced
      }">
          <el-image :src="value.data.imgUrl" />
    </div>
  </div>
</template>

<script>
import config from './config'
export default {
  name: 'ImgHotzone',
  wgtName: '热区图',
  wgtDesc: '',
  wgtIcon: 'wgt-hotzone',
  wgtType: 1,
  wgtTem: 0,//0代表微信和抖音小程序都可以配置,1代表只能配置微信
  config: config,
  props: {
    value: [Object, Array]
  },
  computed: {
    style() {
      return this.value.style ? {
        backgroundImage: `${this.value.style.bgType === 'color' ? '' : `url(${this.value.style.bgPic})`}`,
        backgroundColor: `${this.value.style.bgType === 'color' ? this.value.style.bgColor : ''}`,
        backgroundSize: '100% auto',
        backgroundPosition: 'top', backgroundRepeat: 'no-repeat',
        padding: `${this.value.style.paddedt || 0}px ${this.value.style.paddedr || 0}px ${this.value.style.paddedb || 0}px ${this.value.style.paddedl || 0}px`,
      } : ''
    }
  }
}
</script>
