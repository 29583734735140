<template>
  <div class="stick_lift_attr-item">
    <CompTodoList v-model="localValue" :max="20" type="text" btn-text="添加电梯" @onAddItem="handleAddTabs" v-if="refresh">
      <template slot="body" slot-scope="scope">
        <div class="widget-item">
          <div class="widget-item-bd">
            <el-input v-model="scope.data.title" placeholder="请输入内容" size="small" />
          </div>
          <CompTodoList type="drop" btn-text="添加热区" v-model="localValue[scope.index].children" :max="20"
            @onAddItem="(commond) => handleAddTabZones(commond, scope.index)" btn-type="drop" :dropdownMenu="dropdownMenu">
            <template slot="body" slot-scope="zone">
              <div v-if="localValue[scope.index].children[zone.index].type == 'horizontal'" class="progress-line">
                <AttrHorisetting  v-model='localValue[scope.index].children[zone.index]' :uuid='zone.data.uuid' />
                <el-checkbox v-model="localValue[scope.index].children[zone.index].showProgress">展示进度条</el-checkbox>
              </div>
              <AttrHotsetting v-else v-model='localValue[scope.index].children[zone.index]' :uuid='zone.data.uuid' />
            </template>
          </CompTodoList>
          <el-divider></el-divider>
        </div>
      </template>
    </CompTodoList>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import draggable from 'vuedraggable'
import CompTodoList from '../../comps/comp-todoList'
import AttrHorisetting from '../wgt_horizontal/attr-hotsetting.vue'
import AttrHotsetting from '../wgt_hotzone/attr-hotsetting.vue'
import { v4 as uuidv4 } from 'uuid'

export default {
  components: {
    CompTodoList,
    AttrHorisetting,
    AttrHotsetting,
    draggable
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    uuid: {
      type: String,
      default: ''
    },
    
  },
  data() {
    return {
      localValue: [],
      refresh: true,
      dropdownMenu:[{
        label:'长热区',
        command:'horizontal'
      },{
        label:'热区',
        command:'hotzone'
      }],
      dragOptions: {
        animation: 300,
        forceFallback: false,
        scroll: true
      }
    }
  },
  watch: {
    localValue: {
      handler(nVal) {
        this.$emit('input', nVal)
      },
      deep: true
    },
    uuid: {
      handler() {
        this.localValue = cloneDeep(this.value?.map((item) => {
          const {children = [], ...content} = item
          return {
            children:
              children.map(ele => {
                ele.uuid = uuidv4()
                return ele
              }),
            ...content
          }
        }))
      },
      deep: true,
      immediate: true
    }
  },
  created() {
  },
  methods: {
    handleAddTabs() {
      this.localValue.push({
        title: '',
        children: []
      })
    },
    handleAddTabZones(type, index) {
      this.$set(this.localValue[index], 'children', [...this.localValue[index].children, { type,showProgress:true, data: [], imgUrl: '', uuid: uuidv4() }])
    }
  }
}
</script>

<style lang="scss" scoped>
.widget-item {
  &-bd {
    &-drag {
      margin-top: 10px;
      display: flex;
      align-items: flex-start;
    }
  }
}
.progress-line{
  position: relative;
  .el-checkbox{
    position: absolute;
    top: 0px;
    left: 140px;
    line-height: 32px;
  }
}
</style>
<style lang="scss">
.stick_lift_attr-item {
  .comp-todolist {
    .todo-list {
      align-items: flex-start;
      margin-bottom: 4px !important;

      .widget-item-bd {
        margin-bottom: 4px !important;
      }
    }
  }
}
</style>