// 软文
import {
  WgtSlider
} from '../wgts/index'

export const initiWidgets = []

export const widgets = {
  WgtSlider
}


export default {
  widgets,
  initiWidgets
}
