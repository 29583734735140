<style scoped lang="scss">
.el-menu {
  border: none;
}

.menu-warp {
  height: 100%;
}

.menu-scroll {
  overflow: hidden;
  width: 125px;
}

.menu {
  height: 100%;
  width: 100%;
  padding-top: 60px;
  padding-bottom: 80px;
  box-sizing: border-box;
  overflow-y: overlay;
  margin-right: -15px;
  background: $auxiliary_hue;
  .iconfont {
    color: #fff;
  }
  .el-menu-item {
    height: 45px;
    line-height: 45px;

    &.is-active {
      background: #fff !important;

      i {
        color: $auxiliary_hue;
      }
    }

    i {
      display: inline-block;
      width: 24px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      font-size: 14px;
      color: $dominant_hue;
    }
  }
}

.sub-menu-block {
  width: 130px;
  overflow-y: auto;
  background-color: #f4f4f4;
  box-shadow: 1px 0px 0px 0px rgba(0, 0, 0, 0.06);
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  .el-menu-item {
    &.is-active {
      background-color: #fff;
      color: #333;
    }
  }

  .two-level-menu {
    font-size: 13px;
    color: #999;
    padding: 0 20px;
    height: 30px;
    line-height: 30px;
    margin-top: 20px;
  }
}

.sub-menu {
  height: 100%;
  overflow-y: overlay;
  margin-right: -15px;
  padding: 10px 0;

  .el-menu-item {
    height: 38px;
    line-height: 38px;

    &:hover {
      background-color: rgba(255, 255, 255, 0.5) !important;
    }

    &.is-active {
      background: #fff !important;
    }
  }
}

.el-menu-item-group {
  padding-bottom: 20px;
}

.menu-group {
  position: relative;

  &::after {
    position: absolute;
    left: 20px;
    right: 20px;
    top: 0;
    height: 1px;
    background: #f8f8f8;
    content: '';
  }
}

.sub-menu {
  .el-menu-item-group__title {
    margin-top: 10px;
  }

  .menu-group:first-child::after {
    content: none;
  }
}

.hide_audio {
  position: fixed;
  top: -10000px;
}
</style>

<style lang="scss">
.order-num {
  padding-bottom: 5px;
  font-size: 16px;

  i {
    cursor: pointer;
  }
}

.order-store {
  color: #999;
}

.order-time {
  color: #999;
}

.sub-menu {
  .el-menu-item-group__title {
    padding-top: 20px;
  }

  .el-menu-item:hover {
    background: #f8f8f8;
  }
}

.order-msg {
  .el-icon-message-solid {
    font-size: 22px;
  }

  .is-fixed {
    top: 3px;
  }
}
.el-icon-message-solid {
  font-size: 22px;
  cursor: pointer;
}
</style>

<template>
  <el-container>
    <!--左侧菜单开始-->
    <el-aside class="aside" :class="{ 'no-sub': $route.meta && $route.meta.hideMenu }">
      <div class="menu-warp view-flex">
        <div class="menu-scroll">
          <div class="menu">
            <router-link class="brand" :to="{ path: path_prefixes ? '/' + path_prefixes : '/' }">
              <img :src="brandIco" alt="" />
            </router-link>
            <el-menu
              :default-active="
                $route.matched[0].path ? $route.matched[0].path : $route.matched[1].path
              "
              :background-color="theme.$auxiliary_hue"
              text-color="#fff"
              :active-text-color="theme.$auxiliary_hue"
              @select="handleChange"
            >
              <el-menu-item
                v-for="(item, index) in menuList"
                :key="`menu-item__${index}`"
                :index="item.url"
                @click="toRoute(item.url)"
              >
                <i :class="['iconfont', `icon-${item.icon}`]"></i>
                <span>{{ item.name }}</span>
              </el-menu-item>
            </el-menu>
          </div>
        </div>

        <!-- 二级菜单 -->
        <div v-if="$route.meta && !$route.meta.hideMenu" class="sub-menu-block">
          <el-menu :default-active="activeSubIndex" style="width: 130px; background-color: #f4f4f4">
            <template v-for="(child, cindex) in submenuList" v-if="!child.hidden">
              <div
                v-if="child.children && child.children[0].is_menu"
                :index="`${cindex}`"
                class="menu-group"
                style="background-color: #f4f4f4"
              >
                <div class="two-level-menu">{{ child.name }}</div>
                <div
                  class="menu-three"
                  v-for="sub in child.children"
                  :key="sub.url"
                  style="background-color: #f4f4f4"
                >
                  <el-menu-item
                    v-if="sub.is_show && sub.is_menu"
                    :index="sub.url"
                    :class="[{ 'is-active': sub.url == activeSubIndex }]"
                    style="
                      height: 38px;
                      line-height: 38px;
                      padding: 0;
                      width: 130px;
                      min-width: auto;
                    "
                  >
                    <router-link
                      :to="sub.url"
                      style="
                        color: #666;
                        display: block;
                        height: 100%;
                        line-height: 38px;
                        padding: 0 20px;
                      "
                    >
                      {{ sub.name }}
                    </router-link>
                  </el-menu-item>
                </div>
              </div>
              <div v-else-if="child.is_show && child.is_menu" :index="cindex" class="sub-menu-item">
                <el-menu-item
                  :index="child.url"
                  :class="[{ 'is-active': child.url == activeSubIndex }]"
                  style="height: 38px; line-height: 38px; padding: 0; width: 130px; min-width: auto"
                >
                  <router-link
                    :to="child.url"
                    style="
                      color: #666;
                      display: block;
                      height: 100%;
                      line-height: 38px;
                      padding: 0 20px;
                    "
                  >
                    {{ child.name }}
                  </router-link>
                </el-menu-item>
              </div>
              <!-- <el-submenu
                <el-menu-item-group v-if="child.isLeaf" class="menu-group">
                  <template slot="title">{{ child.name }}</template>
                  <template v-for="(sub, index) in child.children">
                    <el-menu-item :key="sub.path" :index="sub.path" v-if="!sub.hidden"
                      ><span>{{ sub.name }}</span></el-menu-item
                    >
                  </template>
                </el-menu-item-group>
                <div v-else class="menu-group">
                  <el-menu-item :index="child.path" v-if="!child.hidden">{{
                    child.name
                  }}</el-menu-item>
                </div> -->
            </template>
          </el-menu>
        </div>
      </div>
    </el-aside>

    <el-container>
      <el-header class="header" height="50px">
        <div class="view-flex-item">
          <!-- activeSubIndex: {{ activeSubIndex }} -->
          <div class="order-msg" v-if="reminder">
            <!--@click="showDialogVisible"-->
            <el-badge :value="newOrderList.length" :hidden="newOrderList.length <= 0">
              <el-popover title="消息提醒" placement="right" width="550" trigger="hover">
                <el-table :data="newOrderList" height="400px">
                  <el-table-column property="order_id" label="订单号"></el-table-column>
                  <el-table-column property="create_date" label="时间"></el-table-column>
                  <el-table-column property="detail" label="类型"></el-table-column>
                </el-table>
                <i
                  type="text"
                  slot="reference"
                  class="el-icon-message-solid"
                  @click="toOrderList"
                  :style="'color: ' + golder_color"
                ></i>
              </el-popover>
            </el-badge>
          </div>
        </div>
        <div class="view-flex view-flex-middle">
          <div class="icon-nav">
            <el-badge v-if="this.$store.getters.login_type == 'distributor'">
              <i class="iconfont icon-store-alt" @click="handleClickSelectShop"></i>
            </el-badge>
            <!--el-badge v-else>
                <a href="/shopadmin/login" target="_blank"><i class="iconfont icon-store-alt"></i></a>
              </el-badge-->
          </div>
          <div class="icon-nav" v-if="false">
            <el-badge is-dot>
              <i class="iconfont icon-bell"></i>
            </el-badge>
          </div>
          <div class="user-setting">
            <el-popover
              placement="bottom"
              trigger="hover"
              popper-class="logout-popper"
              v-model="visible2"
            >
              <div class="content-padded dom-link" @click="handleUserInfo">
                <div class="view-flex view-flex-middle">
                  <img v-if="avatar" class="user-avatar" :src="avatar" />
                  <i v-else class="user-avatar iconfont icon-user-circle1"></i>
                  <div>
                    <div>{{ nick_name || name }}</div>
                    <small class="muted">基础信息</small>
                  </div>
                </div>
              </div>
              <div class="section-footer with-border small dom-link muted" @click="logout">
                退出登录
              </div>
              <img slot="reference" v-if="avatar" class="user-avatar" :src="avatar" />
              <i v-else slot="reference" class="iconfont icon-user-circle1"></i>
            </el-popover>
          </div>
        </div>
      </el-header>
      <el-main style="position: relative">
        <section id="container" class="content-container">
          <el-col :span="24" class="content-wrapper">
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path + $route.query.t"></router-view>
            </transition>
          </el-col>
        </section>
        <div id="design-view"></div>
      </el-main>
    </el-container>

    <audio class="hide_audio" :src="audio_url" @timeupdate="getCurr" ref="audio" controls></audio>

    <el-dialog
      title="消息列表"
      :visible.sync="dialogTableVisible"
      @close="beforeCloseDialogVisible"
    >
      <el-table :data="newOrderList">
        <el-table-column property="create_date" label="日期"></el-table-column>
        <el-table-column property="order_id" label="订单号"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="small"> 查看 </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </el-container>
</template>
<script>
import { getBrandLogo } from '@/api/common'
import { getAuthorizelogout } from '@/api/login'

import { mapGetters, mapState } from 'vuex'
import store from '../store'
import { websocket } from '@/mixins/websocket'
import cookie from 'js-cookie'
import { getNewOrderNoticeSetting,invalidateToken } from '@/api/login'
import router from '../router'
export default {
  mixins: [websocket],
  // beforeRouteEnter(to, from, next) {
  //   console.log('beforeRouteEnter', to)
  //   next((vm) => {
  //     vm.activeIndex = to.matched[0].path || '/'
  //   })
  // },
  data() {
    return {
      newOrderList: [],
      distributor_id: 0,
      isShowAside: 'false',
      findMenu: false,
      menusList: [],
      homeIndex: '',
      activeIndex: '',
      brandIco: '',
      visible2: false,
      isCurrentSubmenu: function (path) {
        const homePath = this.path_prefixes ? `/${this.path_prefixes}` : '/'
        if (path.indexOf('/shopadmin') !== -1) {
          return path.split('/')[2] == this.$route.path.split('/')[2]
        } else if (path.indexOf('/dealeradmin') !== -1) {
          return path.split('/')[2] == this.$route.path.split('/')[2]
        } else if (path.indexOf('/distiadmin') !== -1) {
          return path.split('/')[2] == this.$route.path.split('/')[2]
        }

        if (path !== homePath) {
          if (this.path_prefixes) {
            return path.split('/')[2] == this.$route.path.split('/')[2]
          } else {
            return path.split('/')[1] == this.$route.path.split('/')[1]
          }
        }
      },
      audio_url: 'https://down.ear0.com:3321/index/preview?soundid=35825&type=mp3&audio=sound.mp3',
      duration: 0,
      is_stop: true, //是否可以播放语音提示
      isDot: false, //是否显示新订单标记
      reminder: false, //门店端显示新订单提示
      dialogTableVisible: false,
      gridData: [
        {
          time: '2022-0-16',
          order_id: '12333445566778899'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['name', 'nick_name', 'avatar', 'wxapp_id', 'template_name', 'golder_color']),
    ...mapState({
      menuList: (state) => {
        const { menus } = state.menu
        return menus
      }
    }),
    submenuList() {
      const fd = this.menuList.find((item) => item.url == this.$route.matched[0].path)
      console.log('this.menuList', this.menuList)
      console.log('submenuList', fd, 'this.$route.matched', this.$route.matched)
      return fd ? fd.children : []
    },
    activeSubIndex() {
      // return this.$route.matched.length > 1 ? this.$route.matched[1].path : ''
      return this.$route.path
    }
  },
  methods: {
    handleClickSelectShop() {
      this.$router.push({
        path: this.path_prefixes
          ? `/${this.path_prefixes}/shopadmin/shoplist`
          : '/shopadmin/shoplist'
      })
    },
    handleChange(key) {
      this.activeIndex = key
    },
    handleUserInfo() {
      console.log("this.matchInternalRoute('admininfo')",this.matchInternalRoute('admininfo'))
      this.$router.push({
        path: this.matchInternalRoute('admininfo')
      })
    },
    toRoute(path) {
      this.$router.push({
        path: path
      })
    },
    logout: async function () {
      if (this.websock) {
        // localStorage.removeItem('newOrderList-' + this.distributor_id)
        try {
          this.websock.close()
        } catch (error) {}
      }
      await invalidateToken()

      this.$store.dispatch('setMenu', [])
      this.$store.dispatch('unsetToken').then(() => {
        window.localStorage.clear()
        window.sessionStorage.clear()
        if (this.system_is_saas == 'true') {
          getAuthorizelogout().then((res) => {
            window.location.href = res.data.data.url
          })
        } else {
          let location = { path: '/login' } // 默认进入管理员登陆
          if (this.path_prefixes) {
            location = { path: `/${this.path_prefixes}/login` }
          } else {
            if (this.$route.path.indexOf('dealeradmin') === 1) {
              // 如果是经销商登陆，退出登陆时进入经销商登陆页面
              location = { path: 'dealeradmin/login' }
            } else if (this.$route.path.indexOf('shopadmin') === 1) {
              // 如果是店铺管理员登陆，退出登陆时进入经销商登陆页面
              location = { path: 'shopadmin/login' }
            } else if (this.$route.path.indexOf('distiadmin') === 1) {
              location = { path: 'distiadmin/login' }
            }
          }
          // this.$router.push({ path: this.path_prefixes ? `/${this.path_prefixes}/login` : `/login` })
          this.$router.push(location)
        }
      })
    },
    // 切换浏览器图标
    changeFavicon(link) {
      let $favicon = document.querySelector('link[rel="icon"]')
      if ($favicon !== null) {
        $favicon.href = link
      } else {
        $favicon = document.createElement('link')
        $favicon.rel = 'icon'
        $favicon.href = link
        document.head.appendChild($favicon)
      }
    },
    // 播放语音提示
    audioPlay() {
      var options = {
        body: '您有新的订单，请及时处理',
        silent: false
      }
      new Notification('新订单通知', options)
      let audio = this.$refs.audio
      if (this.is_stop) {
        this.is_stop = false
        this.isDot = true
        audio.play()
      }
    },
    showDialogVisible() {
      this.dialogTableVisible = true
    },
    beforeCloseDialogVisible() {
      // this.newOrderList = []
      this.dialogTableVisible = false
    },
    // 暂停语音提示
    audioPause() {
      let audio = this.$refs.audio
      audio.pause()
    },
    // 语音提示播放进度
    getCurr() {
      let audio = this.$refs.audio
      if (audio.currentTime === this.duration) {
        this.is_stop = true
        this.isDot = false
        // this.changeFavicon('https://element.eleme.cn/favicon.ico')
      }
    },
    // 获取语音播放总时长
    getDuration() {
      let audio = this.$refs.audio
      audio.oncanplay = () => {
        this.duration = audio.duration
      }
    },

    toOrderList() {
      let type = this.newOrderList[0].type
      console.log(type);
      console.log(this.newOrderList.length-1);

      this.newOrderList = []
      // localStorage.removeItem('newOrderList-' + this.distributor_id)
      let time = new Date().getTime()
      //resend
     // let jump = 'receive'
      let jump ='';
      switch (type) {
        case 'neworder':
          jump = 'receive';
          break;
        case 'order_lock':
        case 'no_delivery':
          jump = 'resend';
          break;
      }
      let orderListRoute = '/shopadmin/order/entitytrade/tradenormalorders?tab='+jump+'&t=' + time
      this.toRoute(orderListRoute)
    }
  },
  created() {
    if (this.$route.path.indexOf('shopadmin') === 1) {
      this.reminder = true
    }
  },
  mounted() {
    getBrandLogo()
      .then((res) => {
        if (res.data.data.logo) {
          this.brandIco = res.data.data.logo
        } else {
          this.brandIco = require('../../static/img/' + this.companyBrandImg + '/logo_ico.svg')
        }
      })
      .catch(() => {
        this.brandIco = require('../../static/img/' + this.companyBrandImg + '/logo_ico.svg')
      })
    this.activeIndex = this.$route.meta.sn

    this.$nextTick(() => {
      //获取音频总时长
      this.getDuration()
    })

    if (this.$store.getters.login_type == 'distributor') {
      this.distributor_id = cookie.get('shopid')
      getNewOrderNoticeSetting().then((response) => {
        if (response.data.data.is_open == '1') {
          this.initWebSocket()
        }
      })
    }

    if (!('Notification' in window)) {
      this.$message.error('浏览器不支持消息通知')
      return
    }
    Notification.requestPermission(function (permission) {})
  },

  beforeDestroy() {
    /*if (this.distributor_id && this.newOrderList.length > 0) {
      localStorage.setItem('newOrderList-' + this.distributor_id, JSON.stringify(this.newOrderList))
    }*/
    this.closeWebsocket()
  }
}
</script>
