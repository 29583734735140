<style lang="scss" scoped>
.wgt-classify {
  display: flex;
  width: 100%;

  &-slider {
    height: fit-content;
    overflow: hidden;

    &-item {
      color: #000;
      min-height: 21px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &-content {
    flex: 1;
    flex-shrink: 0;
  }
}
</style>
<style lang="scss">
.wgt-classify {
  .el-carousel {
    width: auto !important;
  }

  .wgt-around-store {
    font-size: 14px !important;
  }
}
</style>

<template>
  <div :class="{ 'wgt-classify': true }">
    <div class="wgt-classify-slider" :style="navStyle">
      <div class="wgt-classify-slider-item" v-for="(item, index) in value.data" :key="index"
        :style="navItemStyle(index)" @click="handleClickNavItem(index)">
        {{ item.name }}
      </div>
    </div>
    <div class="wgt-classify-content" :style="contentStyle" v-if="show">
      <component v-for="(wgt, index) in value.data[curIndex]?.cusTom?.template" :is="wgt.name" :value="wgt"
        :key="`content-part-component__${index}`" />
    </div>
  </div>
</template>

<script>
import config from './config'

export default {
  name: 'classify',
  wgtName: '分类',
  wgtDesc: '',
  wgtIcon: 'wgt_content-part',
  wgtType: 1,
  wgtTem: 0,//0代表微信和抖音小程序都可以配置,1代表只能配置微信
  config: config,
  props: {
    value: [Object, Array]
  },
  data() {
    return {
      curIndex: 0,
      show:false
    }
  },
  mounted() {
  },
  methods: {
    handleClickNavItem(index) {
      this.curIndex = index
    },
    navItemStyle(index) {
      const { navsize, navitempadded: { paddedtb, paddedlr }, bgcolor: { dot_noCover, dot_cover }, fontcolor: { dot_noCover: color, dot_cover: selectColor }, selectnav: { size, weight }, textAlign } = this.value
      // this.curIndex == index ? bgcolor :
      return {
        fontSize: `${this.curIndex == index ? size : navsize}px`,
        padding: `${paddedtb || 0}px ${paddedlr || 0}px`,
        color: this.curIndex == index ? selectColor : color,
        fontWeight: this.curIndex == index ? weight : 'normal',
        backgroundColor: this.curIndex == index ? dot_cover : dot_noCover,
        textAlign: textAlign
      }
    },
  },
  computed: {
    navStyle() {
      const { combg: { bgType, color, imageUrl }, navpadded: { paddedt, paddedb, }, navwidth = 0, rounded: { upleft, upright, bottomright, bottomleft } } = this.value
      const style = {
        padding: `${paddedt || 0}px 0 ${paddedb || 0}px 0`,
        minWidth: `${navwidth}px`,
        maxWidth: `${navwidth}px`,
        borderRadius: `${upleft || 0}px ${upright || 0}px ${bottomright || 0}px ${bottomleft || 0}px`,
      }
      if (bgType == 'color') {
        return {
          backgroundColor: color,
          ...style
        }
      } else {
        return {
          backgroundImage: `url(${imageUrl})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'top center',
          ...style
        }
      }
    },

    contentStyle() {
      const { contentpadded: { paddedt, paddedb, paddedl, paddedr, }, navwidth = 0 } = this.value
      return {
        padding: `${paddedt || 0}px ${paddedr || 0}px ${paddedb || 0}px ${paddedl || 0}px`,
        width: `calc(100% - ${navwidth}px)`,
      }

    }
  },
  watch: {
    value:{
      handler(val){
        this.show = false
        this.$nextTick(()=>{
          this.show = true
        })
      },
      deep:true,
      immediate:true
    }
  }
}
</script>