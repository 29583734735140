<style lang="scss" scoped>
.comp-picker-link {
  .btn-item {
    display: flex;
    align-items: center;
    .btn-linkpath{
      flex: 1;
    }
    .input-m {
      margin-left: 3px;
    }
  }

  .linktype-radio {
    margin-top: 10px;
    margin-bottom: 6px;

    .el-radio {
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }
    }

    .el-radio__label {
      padding-left: 2px;
      font-size: 13px;
    }
  }

  .btn-linkpath {
    padding: 0 8px;
    color: var(--themeColor);
    border: 1px solid var(--themeColor);
    background-color: #fff;
    font-size: 12px;
    height: 32px;
    line-height: 32px;
    border-radius: 3px;
    max-width: 200px;
    @include text-overflow();
  }

  input::-webkit-input-placeholder {
    font-size: 12px;
  }

  .el-input__inner {
    padding: 0 8px;
  }

  .h5-link {
    margin-top: 10px;
    display: block;
  }

  .video-list {
    display: flex;
  }
}
</style>
<template>
  <div class="comp-picker-link">
    <div class="btn-item">
      <div class="btn-linkpath" @click="onPickerPath">
        {{ getLabelName() }}
      </div>
      <el-select class="input-m" v-model="localValue.rule_id" placeholder="请选择" filterable remote clearable
        v-if="localValue && localValue.id == 'drawer-shop'" size="small">
        <el-option v-for="item in list" :key="item.rule_id" :label="item.rule_title" :value="item.rule_id">
        </el-option>
      </el-select>
      <el-input v-model="localValue.liveId" placeholder="请填写抖音直播ID" v-if="localValue && localValue.id=='douyinLive'" size="small" class="input-m" />
      <el-input v-model="localValue.imId" placeholder="请填写抖音客服ID" v-if="localValue && localValue.id=='douyinIm'" size="small" class="input-m" />
      <el-input v-model="localValue.byte_mini_shop_id" placeholder="请填写抖音小店ID" v-if="localValue && localValue.id=='douyinStore'" size="small" class="input-m" />
    </div>
    <div v-if="localValue.id === 'CopyLink'">
      <el-input v-model="localValue.CopyUrl" placeholder="请输入链接" class="h5-link" size="small" type="text" />
      <el-input v-model="localValue.CopyTitle" placeholder="请输入复制文案" class="h5-link" size="small" type="text" />
    </div>
    <div v-if="localValue.id === 'thirdpage'">
      <el-input v-model="localValue.linkTitle" placeholder="请输入跳转路径" class="h5-link" size="small" type="text" />
      <el-input v-model="localValue.linkAppid" placeholder="请输入对应小程序APPID" class="h5-link" size="small" type="text" />
      <div v-if="isShowLinkprogram">
        <span>是否半屏跳转:</span>
        <el-radio-group v-model="localValue.linkProgram" style="display: inline-block;padding-left: 10px;">
          <el-radio label="1">否</el-radio>
          <el-radio label="2">是</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div v-if="localValue.id === 'videopage' && isShowVideo">
      <el-radio-group v-model="localValue.autoplay" class="linktype-radio">
        <el-radio :label="true"> 开启自动播放 </el-radio>
        <el-radio :label="false"> 关闭自动播放 </el-radio>
      </el-radio-group>
      <div class="video-list">
        <SpImagePicker v-model="localValue.video_cover" size="small" class="video-link" text="封面" />
        <SpVideoPicker type="object" v-model='localValue.video' size='small' class="video-link" />
      </div>
    </div>

  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import { mapGetters } from "vuex";
import { isObject } from '@/utils'
import { getShowRuleList } from '@/api/template'
import { LINK_PATH } from '@/consts'
export default {
  name: 'CompHotPicker',
  props: {
    value: {
      type: [Object, Array],
      default: () => { }
    },
    isShowH5Link: {
      // 不展示自定义链接label true：展示
      type: Boolean,
      default: true
    },
    isShowLinkprogram: {
      type: Boolean,
      default: false
    },
    isShowVideo: {
      type: Boolean,
      default: true
    },
    wgtType:{
      type: String,
      default: ''
    }
  },
  data() {
    return {
      localValue: {
        linkPage: '',
        id: '',
        title: '',
        ctoken:'',
        linkType: 0,
        linkUrl: '',
        video_cover: '',
        CopyUrl: "",
        CopyTitle: "",
        autoplay: false
      },
      list: []
    }
  },
  watch: {
    // value: {
    //   immediate: true,
    //   handler(nVal) {
    //
    //     this.localValue = {
    //       ...this.localValue,
    //       ...nVal
    //     }
    //   }
    // }
    localValue: {
      deep: true,
      handler: function (nVal, oVal) {
        this.$emit('input', nVal)
        this.$emit('change', nVal)
      }
    }
  },

  computed: {
    ...mapGetters([
      'template_name',
      'wxapp_id'
    ])
  },
  created() {
    const localValue = Object.assign(this.localValue, this.value)
    const { video } = localValue
    localValue.video = isObject(video) ? video : {}
    this.localValue = cloneDeep(localValue)
    if (localValue.id == 'drawer-shop') {
      this.getList()
    }
  },
  methods: {
    async onPickerPath() {
      const { template_douyin = 0 } = this.$route.query
      const { linkPage, id } = this.localValue
      const res = await this.$picker.path({
        data: id,
        tab: linkPage,
        multiple: false,
        wxapp_id: this.wxapp_id,
        template_name: this.template_name,
        isDouyin: template_douyin == 1,
        wgtType:this.wgtType,
        isHotWords: false
      })

      this.localValue = {
        ...res,
        linkType: 0,
        linkUrl: '',
        video_cover: '',
        CopyUrl: "",
        CopyTitle: "",
        autoplay: false,
        video:{}
      }
      if (res.id == "drawer-shop") {
        this.getList()
      } else {
        this.$set(this.localValue, 'rule_id', '')
      }
    },
    getLabelName() {
      const { linkPage, title, id } = this.localValue
      if (linkPage) {
        return `${LINK_PATH[linkPage]}：${id ? '[' + id + ']' : ''} ${title}`
      } else {
        return '选择路径'
      }
    },
    async getList() {
      const { template_douyin = 0 } = this.$route.query
      const res = await getShowRuleList({
        page: 1,
        page_size: -1,
        platform: template_douyin == 1 ? 'byte_mini' : 'wxapp',
      })
      this.list = [{ rule_id: '0', rule_title: '全部门店' }, ...res.data.data.list]
    }
  }
}
</script>
