// 抖音自定义页面装修
import {
  WgtSlider,
  WgtNewSlider,
  WgtHotZone,
  WgtFilm,
  WgtAroundService,
  WgtWallPaper,
  WgtFooterNav,
} from '../wgts/index'

export const initiWidgets = []

export const widgets = {
  WgtSlider,
  WgtNewSlider,
  WgtHotZone,
  WgtFilm,
  WgtAroundService,
  WgtWallPaper,
  WgtFooterNav,
}


export default {
  widgets,
  initiWidgets
}
