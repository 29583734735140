// 商城自定义模块(通用)
import {
  WgtSlider,
  WgtNewSlider,
  WgtHotZone,
  WgtHoriZontal,
  WgtblokScroll,
  WgtFilm,
  WgtAbtest,
  WgtAroundService,
  WgtStickyLift,
  WgtTabsCut,
  WgtOldGoods,
  WgtOldEvaluate,
  WgtWallPaper,
    WgtAroundStore,
  WgtAroundStoreOne,
  WgtProductShelves,
  WgtImageText
  // WgtContentPart
} from '../wgts/index'

export const initiWidgets = []

export const widgets = {
  WgtSlider,
  WgtNewSlider,
  WgtHotZone,
  WgtHoriZontal,
  WgtblokScroll,
  WgtFilm,
  WgtAbtest,
  WgtAroundService,
  WgtStickyLift,
  WgtTabsCut,
  WgtOldGoods,
  WgtOldEvaluate,
  WgtWallPaper,
    WgtAroundStore,
  WgtAroundStoreOne,
  WgtProductShelves,
  WgtImageText
  // WgtContentPart
}


export default {
  widgets,
  initiWidgets
}
