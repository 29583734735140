import { pickBy } from "@/utils";
import AttrHotSetting from "./attr-hotsetting";
import CompsStyle from "../../comps/comps-style.vue";

export default {
  name: "imgHotzone",
  setting: [
    { label: "标题", key: "title", component: "input", value: "热区图" },
    {
      label: "副标题",
      key: "subtitle",
      component: "input",
      value: "图上随意画块块",
    },
    { label: "楼层", key: "floor_var", component: "input" },
    { label: "组件间距", key: "padded", component: "switch", value: true },
    // {
    //   label: "样式设置",
    //   key: "style",
    //   component: function (h, { key }) {
    //     return <CompsStyle v-model={this.value[key]} uuid={this.value.uuid} />;
    //   },
    //   value: {
    //     margined: 0,
    //     padded: 0,
    //     paddedt: 0,
    //     paddedb: 0,
    //     paddedl: 0,
    //     paddedr: 0,
    //     bgColor: "",
    //     bgPic: "",
    //     bgType: "color",
    //   },
    // },
    // {
    //   label: '实验数值',
    //   key: 'testGroup',
    //   component: 'input',
    //   tip: "多个用','号隔开",
    //   value: '',
    //   isShow: function () {
    //     return this.value.abtest
    //   }
    // },
    {
      label: "热区设置",
      key: "data",
      component: function (h, { key }) {
        return (
          <AttrHotSetting v-model={this.value[key]} uuid={this.value.uuid} />
        );
      },
      value: { imgUrl: "", data: [] },
    },
  ],
  transformIn: (v) => {
    const {
      name,
      base,
      config: { imgUrl },
      data,
    } = v;
    return {
      name,
      ...base,
      data: {
        imgUrl,
        data,
      },
    };
  },
  transformOut: (v) => {
    return pickBy(v, {
      name: "name",
      base: (v) => {
        return pickBy(v, {
          title: "title",
          subtitle: "subtitle",
          padded: "padded",
          floor_var: "floor_var",
          // style: "style",
        });
      },
      config: ({ data }) => {
        return pickBy(data, {
          imgUrl: "imgUrl",
        });
      },
      data: "data.data",
    });
  },
};
