<template>
  <div :class="{
    'wgt-around-store': true,
    'padded': value.padded
  }">
    <!-- <div class="wgt-hd"> -->
    <el-image class="wgt-bg" :src="value.bgImg" fit="height-contain" v-if="value.bgImg" />
    <div class="wgt-bd-row" :style="{
      'width': value.width + '%',
      'bottom': value.bottom + 'px'
    }
      ">
      <p class="address" :style="storeStyle">国贸商城店<i class="el-icon-arrow-right" /></p>
      <p class="distance" :style="textsettingStyle" v-if="value.deliveryInfo == 1">
        <span :style="distanceStyle">2.3km</span><span>离你最近</span>
      </p>
      <p class="distance" :style="textsettingStyle" v-if="value.deliveryInfo == 2">
        <span :style="distanceStyle">2.3km</span><span class="time" :style='{ borderColor: value?.textStyle?.color }'>|</span><span>最快<span :style="distanceStyle">43分钟</span>送达</span>
      </p>
    </div>
  </div>
</template>

<script>
import config from './config'
export default {
  name: 'AroundStoreOne',
  wgtName: '身边门店-新',
  wgtDesc: '',
  wgtIcon: 'wgt-around-store',
  wgtType: 1,
  wgtTem: 0,//0代表微信和抖音小程序都可以配置,1代表只能配置微信
  config,
  props: {
    value: [Object, Array]
  },
  computed: {
    // stylebg() {
    //   return {
    //     backgroundImage: `url(${this.value.bgImg})`,
    //     backgroundSize: '100% auto',
    //     backgroundPosition: 'top',
    //     backgroundRepeat: 'no-repeat',
    //   }
    // },
    storeStyle() {
      const { storeStyle } = this.value || {}
      return {
        color: storeStyle?.color,
        fontSize: storeStyle?.fontSize + 'px',
      }
    },
    distanceStyle() {
      const { deliveryStyle } = this.value || {}
      return {
        color: deliveryStyle?.color,
        fontSize: deliveryStyle?.fontSize + 'px',
      }
    },
    textsettingStyle() {
      const { textStyle } = this.value || {}
      return {
        color: textStyle?.color,
        fontSize: textStyle?.fontSize + 'px',
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wgt-around-store {
  position: relative;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  min-height: 20px;

  .wgt-bg {
    display: block;
  }

  .wgt-bd-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    box-sizing: border-box;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
  }

  .time {
    position: relative;
    padding:0 5px;
  }

  .distance {
    letter-spacing: 1px;
    font-weight: 200;
    display: flex;
    align-items: center;
  }
}
</style>