import { pickBy } from "@/utils";
import StyleSetting from "./style-setting.vue";
export default {
  name: "aroundStoreOne",
  setting: [
    // {
    //   label: "样式选择",
    //   key: "style",
    //   component: "radio",
    //   options: [
    //     { name: "样式一", label: "one" },
    //     { name: "样式二", label: "two" },
    //   ],
    // },
    {
      label: "背景图",
      key: "bgImg",
      component: function (h, { key }) {
        return <SpImagePicker v-model={this.value[key]} size="small" />;
      },
      value: "",
    },  {
      label: "宽度",
      key: "width",
      component: "number",
      position: "right",
      append:"%",
      value: 100,
      max: 100,
      min: 0,
    }, {
      label: "底部距离",
      key: "bottom",
      component: "number",
      position: "right",
      append:"px",
      min: 0,
      value: 0,
    },{
      label: "门店样式",
      key: "storeStyle",
      component: function (h, { key }) {
        return (
          <StyleSetting
            v-model={this.value[key]}
            uuid={this.value.uuid}
          />
        );
      },
      value: {
        color: "#000",
        fontSize: "12",
      }
    }, {
      label: "数据样式",
      key: "deliveryStyle",
      component: function (h, { key }) {
        return (
          <StyleSetting
            v-model={this.value[key]}
            uuid={this.value.uuid}
          />
        );
      },
      value: {
        color: "#000",
        fontSize: "12",
      }
    }, {
      label: "文案样式",
      key: "textStyle",
      component: function (h, { key }) {
        return (
          <StyleSetting
            v-model={this.value[key]}
            uuid={this.value.uuid}
          />
        );
      },
      value: {
        color: "#000",
        fontSize: "12",
      }
    }, {
      label: "配送信息",
      key: "deliveryInfo",
      component: "radio",
      options: [
        { name: "门店距离", label: "1" },
        { name: "门店距离+配送时效", label: "2" },
      ],
      value: "1"
    }
  ],
  transformIn: (v) => {
    const { name, base, config } = v;
    return {
      name,
      ...base,
      ...config
    };
  },
  transformOut: (v) => {
    return pickBy(v, {
      name: "name",
      base: (v) => {
        return {}
      },
      config: (v) => {
        return pickBy(v, {
          bgImg: "bgImg",
          storeStyle: "storeStyle",
          deliveryStyle: "deliveryStyle",
          textStyle:'textStyle',
          deliveryInfo: "deliveryInfo",
          width: 'width',
          bottom:'bottom'
          // style: "style"
        });
      }
    });
  },
};
